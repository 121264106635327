<template>
  <div class="tc-evaluate-batchSetPermissions">
    <!-- 头部工具栏 -->
    <div class="tool">
      <ul>
        <li>
          <img src="@/assets/images/refreshBtn.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="@/assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <!-- 模态框 查询层 -->
    <el-dialog
      title="查询信息"
      :close-on-click-modal="false"
      :visible.sync="queryBox"
      width="30%"
      center
    >
      <el-form ref="form" :model="form" label-width="120px" size="mini">
        <el-form-item label="门诊号/住院号">
          <el-input v-model="form.number"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="form.sex">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出生范围">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="科室名称">
          <el-col>
            <el-checkbox-group v-model="form.department_scope">
              <el-checkbox
                :label="item.id"
                name="type"
                v-for="item in departmentList"
                :key="item.id"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="queryBox = false">取 消</el-button>
        <el-button type="primary" @click="queryBox = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 数据渲染表格 -->
  </div>
</template>

<script>
import { loadDepartmentData } from "@/api/comm.js";
export default {
  inject: ["reload"],
  data() {
    return {
      form: {
        sex: "",
        date: "",
        department_scope: [],
        name: "",
        number: "",
      },
      departmentList: [],
      queryBox: true,
    };
  },
  created() {
    // 获取所有科室
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="less">
.tc-evaluate-batchSetPermissions {
  margin-top: 30px;
  position: relative;
  // 头部工具
  .tool {
    position: absolute;
    top: -80px;
    right: 0;
    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;
        img {
          width: 100px;
        }
      }
    }
  }
  .el-input__inner {
    width: 200px;
  }
}
</style>